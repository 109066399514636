import { useAuthContext } from '@indomita-react/auth-provider';
import { useTranslations } from '@pepita-react/i18n';

import { useManageSavedSearchLink } from '../hooks/useManageSavedSearchLink';

import { PepitaButton } from 'src/libs/ui/pepita-button';
import { PepitaDialog } from 'src/libs/ui/pepita-dialog';

export function ManageSavedSearchDialog({ onClose }: { onClose: () => void }) {
  const { trans } = useTranslations();
  const { user } = useAuthContext();
  const { showAction, link, label } = useManageSavedSearchLink();

  return (
    <PepitaDialog
      disableCloseOnBackdropClick
      title={trans('disable_alert')}
      content={
        user
          ? trans('manage_saved_search_dialog_text')
          : trans('manage_saved_search_dialog_text_anonymous')
      }
      onClose={onClose}
      footer={
        <>
          <PepitaButton onClick={onClose}>{trans('act_cancel')}</PepitaButton>
          {showAction && link && (
            <PepitaButton variant="accent" as="a" href={link} target="_blank">
              {label}
            </PepitaButton>
          )}
        </>
      }
    />
  );
}
