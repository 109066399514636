import { createAtomicStateDerivedAtom } from 'src/atoms/atomic-state';

import { geographySearchParamsAtom } from 'src/components/GeographySearch/atoms';
import { mapBoundsAtom, mapZoomAtom } from 'src/components/MapComponent/atoms';
import { formStateAtom } from 'src/components/SearchFilters/atoms';
import type { fieldConfigType } from 'src/components/SearchFilters/config/formConfigTypes';
import { hiddenFieldsAtom } from 'src/components/SearchFilters/hooks/useDetectHiddenFields';
import type { FormStateType } from 'src/components/SearchFilters/types';

import type { RealEstateKey } from 'src/types/real-estate';
import type { SearchListingData } from 'src/types/real-estate-lite';

import { removeEmptyKeys } from 'src/utils/object';

export type RealEstateListSearchWithLocalData = SearchListingData & {
  refill: RealEstateKey[];
  waitingForRefill: RealEstateKey[];
};

export const formSearchParamsAtom = createAtomicStateDerivedAtom({
  key: 'FormSearchParamsWithoutHidden',
  get({ get }) {
    const searchParams: FormStateType = {};

    const searchFormState = get(formStateAtom);

    // Removing hidden and empty fields from search params
    const hiddenFields: (fieldConfigType | null)[] = get(hiddenFieldsAtom);
    const paramsToAdd = Object.keys(searchFormState).filter(
      (fieldName) =>
        !hiddenFields.find((hiddenField) => hiddenField?.field === fieldName)
    );

    paramsToAdd.forEach(
      (param) => (searchParams[param] = searchFormState[param])
    );

    return removeEmptyKeys(searchParams);
  },
});

export const realEstateSearchParamsAtom = createAtomicStateDerivedAtom({
  key: 'RealEstateSearchParams',
  get({ get }) {
    const bounds = get(mapBoundsAtom);
    const geography = get(geographySearchParamsAtom);
    const zoom = get(mapZoomAtom);
    const formSearchParams = get(formSearchParamsAtom);

    return {
      ...bounds,
      ...geography,
      zoom,
      ...formSearchParams,
    };
  },
  deepEqualityCheck: true,
});
