import type { ChangeEvent } from 'react';
import clsx from 'clsx';

import { PepitaToggle } from 'src/libs/ui/pepita-toggle';

import css from './styles.module.scss';

interface ToggleSwitchProps {
  label: string;
  onChange: (evt: ChangeEvent<HTMLInputElement>) => void;
  checked?: boolean;
  disabled?: boolean;
}

export function ToggleSwitch({
  label,
  checked = false,
  onChange,
  disabled = false,
}: ToggleSwitchProps) {
  return (
    <div className={css['in-saveSearchDialogOptionSwitch__container']}>
      <span className={css['in-saveSearchDialogOptionSwitch__label']}>
        {label}
      </span>
      <PepitaToggle
        checked={checked}
        readOnly
        onChange={onChange}
        customClass={clsx(
          css['in-saveSearchDialogOptionSwitch'],
          disabled && css['is-disabled']
        )}
      />
    </div>
  );
}
