import { ENTITY_TYPE } from '@indomita-website/map-polygons/src/types';

import { useAtomicStateAtomValue } from 'src/atoms/atomic-state';

import { useSearchListParams } from 'src/components/SearchList/hooks/useSearchListParams';
import {
  geographySearchAtom,
  geographySearchParamsAtom,
} from '../components/GeographySearch/atoms';

export const useCheckSaveSearchDisabled = (): boolean => {
  const geographyParams = useAtomicStateAtomValue(geographySearchParamsAtom);
  const geography = useAtomicStateAtomValue(geographySearchAtom);
  const searchParams = useSearchListParams();

  const hasMacroArea = Boolean(geographyParams?.idMacroarea);
  const isLuxury = Boolean(searchParams.lusso);
  const noAgenciesSearch = searchParams.noAgenzie === '1';
  const lowCostSearch = searchParams.lowcost === '1';
  const seaViewSearch = searchParams.seaView === '1';
  const seaDistanceSearch = Boolean(searchParams.seaDistance);
  const isCountryOrRegionSearch =
    geography?.searchType === 'place' &&
    (geography.value[0].type === ENTITY_TYPE.country ||
      geography.value[0].type === ENTITY_TYPE.region);

  return (
    hasMacroArea ||
    isLuxury ||
    noAgenciesSearch ||
    lowCostSearch ||
    seaViewSearch ||
    seaDistanceSearch ||
    isCountryOrRegionSearch
  );
};
