import type { RawSavedSearchResult } from 'src/repository/user/saved-searches/raw-data-types';

import type { SearchParams } from 'src/types/search';

import { hasTruthyProperties } from './object';

type SearchFilters = SearchParams | RawSavedSearchResult;

export type ActiveSearchFilters = (keyof SearchFilters)[];

export const getHasActiveSearchFilters = (
  target: SearchFilters,
  activeSearchFilters: ActiveSearchFilters
): boolean => hasTruthyProperties(target, activeSearchFilters);
