import { useEffect, useState } from 'react';
import { useTranslations } from '@pepita-react/i18n';
import clsx from 'clsx';
import { useAtomValue } from 'jotai';

import { useSaveSearchStatus } from './hooks/useSaveSearchStatus';

import { PepitaToggle } from 'src/libs/ui/pepita-toggle';

import { searchOnMapAtom } from '../RealEstateSearchMap/atoms/searchOnMapAtom';
import { searchInfoAtom } from '../SearchList/atoms/searchInfoAtom';

import { ManageSavedSearchDialog } from './ManageSavedSearchDialog';
import { PreviousSearchAlertDialog } from './PreviousSearchAlertDialog';
import { SaveSearchButton } from './SaveSearchButton';
import { SaveSearchDialog } from './SaveSearchDialog';

import css from './styles.module.scss';

export type SaveSearchProps = {
  position: 'box' | 'searchbar' | 'sidebar' | 'autoOpen';
  customClass?: string;
  type?: 'button' | 'toggle' | 'link';
};

export type SaveSearchDialogStates = 'open' | 'close' | 'opening';

export function SaveSearch({
  position,
  customClass,
  type = 'button',
}: SaveSearchProps) {
  const { trans } = useTranslations();

  /**
   * Lo stato della modale 'opening' serve a rappresentare quello stato in cui
   * i dati della ricerca sono ancora in caricamento ma l'utente ha già deciso di
   * volerla salvare.
   *
   * In questo caso appena i dati saranno disponibili si deciderà se aprire la modale o meno
   * (in base al fatto che la ricerca sia stata già salvata con questi parametri)
   */
  const [modalState, setModalState] = useState<SaveSearchDialogStates>('close');

  const [showManageSearchDialog, setShowManageSearchDialog] =
    useState<boolean>(false);

  const searchInfo = useAtomValue(searchInfoAtom);

  const status = useSaveSearchStatus();

  const searchOnMap = useAtomValue(searchOnMapAtom);

  const [previousSearchAlertDialog, setPreviousSearchAlertDialog] =
    useState<boolean>(false);

  function handleClose() {
    setModalState('close');
  }

  function handleOpen() {
    setPreviousSearchAlertDialog(Boolean(searchOnMap));

    if (status === 'saved' || status === 'updated') return;

    if (searchInfo?.isFetching) {
      setModalState('opening');
    } else {
      setModalState('open');
    }
  }

  function handleToggleClick() {
    if (status === 'new') return handleOpen();

    return setShowManageSearchDialog(true);
  }

  const getLinkLabel = () => {
    if (status === 'saved') {
      return trans('user_lbl_saved_search_extended');
    }

    if (status === 'update') {
      return trans('act_update');
    }

    if (status === 'updated') {
      return trans('user_lbl_updated_search_extended');
    }

    return trans('act_save');
  };

  // Questo effect serve ad uscire dallo stato in cui non abbiamo ancora i dati
  // ma l'utente ha già cliccato il pulsante
  useEffect(() => {
    if (modalState !== 'opening') return;
    if (searchInfo?.isFetching) return;

    // Se la ricerca risulta salvata invece di aprire la modale mostriamo all'utente
    // che la ricerca è già salvata
    if (searchInfo?.searchId) {
      setModalState('close');
    } else {
      setModalState('open');
    }
  }, [searchInfo?.isFetching, modalState, searchInfo?.searchId]);

  return (
    <>
      {modalState === 'open' && !previousSearchAlertDialog ? (
        <SaveSearchDialog
          editSearch={status === 'update'}
          onClose={handleClose}
          entryPointPosition={position}
        />
      ) : null}
      {/* When user draws on the map, the geography is not updated.
          So show a warning dialog to inform the user
          that they are attempting to save the old geography
       */}
      {modalState === 'open' && previousSearchAlertDialog && (
        <PreviousSearchAlertDialog
          onClose={() => {
            setModalState('close');
            setPreviousSearchAlertDialog(false);
          }}
          onConfirm={() => setPreviousSearchAlertDialog(false)}
        />
      )}

      {showManageSearchDialog ? (
        <ManageSavedSearchDialog
          onClose={() => setShowManageSearchDialog(false)}
        />
      ) : null}

      {type === 'toggle' && (
        <PepitaToggle
          onClick={handleToggleClick}
          // Optimistic UI
          checked={modalState !== 'close' || status !== 'new'}
          customClass={customClass}
        />
      )}

      {type === 'link' && (
        <a
          onClick={handleOpen}
          onKeyDown={handleOpen}
          role="button"
          tabIndex={0}
          className={clsx(
            css['in-saveSearch__link'],
            status === 'saved' && css['in-saveSearch__link--saved'],
            customClass
          )}
        >
          {getLinkLabel()}
        </a>
      )}

      {type === 'button' && (
        <SaveSearchButton
          onClick={handleOpen}
          modalState={modalState}
          status={status}
          icon={position !== 'box' ? { name: 'bell' } : undefined}
          customClass={customClass}
        />
      )}
    </>
  );
}

export { AutoSaveSearchDialog } from './AutoSaveSearchDialog';
export { SaveSearchBox } from './SaveSearchBox';
export { SearchSummary } from './SearchSummary';
