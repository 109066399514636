import { useAtomValue } from 'jotai';

import { searchInfoAtom } from 'src/components/SearchList/atoms/searchInfoAtom';

export type SaveSearchStatus = 'new' | 'saved' | 'update' | 'updated';

export const useSaveSearchStatus = (): SaveSearchStatus => {
  const searchInfo = useAtomValue(searchInfoAtom);

  if (searchInfo?.isEditing) {
    return 'update';
  }

  if (searchInfo?.isUpdated) {
    return 'updated';
  }

  if (searchInfo?.searchId) {
    return 'saved';
  }

  return 'new';
};
