import { useTranslations } from '@pepita-react/i18n';
import { useBreakpoint } from '@pepita-react/use-breakpoint';
import clsx from 'clsx';

import type { SaveSearchDialogStates } from 'src/components/SaveSearch';

import type { SaveSearchStatus } from '../hooks/useSaveSearchStatus';

import { PepitaButton } from 'src/libs/ui/pepita-button';
import type { PepitaIconProps } from 'src/libs/ui/pepita-icon';

import type { Trans } from 'src/utils/i18nFormatters';

import css from './styles.module.scss';

const getButtonLabel = (
  status: SaveSearchStatus,
  maxScreenWidthSm: boolean,
  trans: Trans
) => {
  if (status === 'saved') {
    if (maxScreenWidthSm) {
      return trans('user_lbl_saved_search', { count: 1 });
    }

    return trans('user_lbl_saved_search_extended');
  }

  if (status === 'updated') {
    if (maxScreenWidthSm) {
      return trans('user_lbl_updated_search', { count: 1 });
    }

    return trans('user_lbl_updated_search_extended');
  }

  if (maxScreenWidthSm) {
    if (status === 'update') {
      return trans('act_update');
    }

    return trans('act_save');
  }

  return status === 'update'
    ? trans('act_update_search')
    : trans('act_activate_for_free');
};

type SaveSearchButtonProps = {
  onClick: () => void;
  icon: PepitaIconProps | undefined;
  customClass?: string;
  modalState: SaveSearchDialogStates;
  status: SaveSearchStatus;
};

export function SaveSearchButton({
  onClick,
  icon,
  customClass,
  modalState,
  status,
}: SaveSearchButtonProps) {
  const { trans } = useTranslations();
  const [maxScreenWidthSm] = useBreakpoint({ to: 'sm' });
  const inactive = status === 'saved' || status === 'updated';

  return (
    <PepitaButton
      as={inactive ? 'span' : 'button'}
      type={inactive ? undefined : 'button'}
      variant={inactive ? 'link' : 'accent'}
      onClick={onClick}
      customClass={clsx(
        inactive && css['in-saveSearchButton--inactive'],
        customClass
      )}
      icon={icon}
      loading={modalState === 'opening'}
      data-cy={'save-search-button'}
    >
      <span
        className={
          maxScreenWidthSm
            ? undefined
            : css['in-saveSearchButton--mobileHidden']
        }
      >
        {getButtonLabel(status, maxScreenWidthSm, trans)}
      </span>
    </PepitaButton>
  );
}
