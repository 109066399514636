import { useCallback, useEffect, useState } from 'react';
import { SniperLinkContent } from '@indomita-react/sniper-link';
import { useTranslations } from '@pepita-react/i18n';
import { clsx } from 'clsx';
import { useAtomValue } from 'jotai';

import { authModalAtom } from 'src/components/AuthModal/atoms/authModalAtom';
import type { SaveSearchProps } from 'src/components/SaveSearch';
import { SniperLinkButtonWrapper } from 'src/components/SniperLinkButtonWrapper';

import type { ProductConfig } from 'src/config/product';
import { getProductConfig } from 'src/config/product';

import { getAlertType, useSaveSearchForm } from '../hooks/useSaveSearchForm';
import { useSaveSearchTracking } from '../hooks/useSaveSearchTracking';

import { PepitaDialog, PepitaDialogFrameType } from 'src/libs/ui/pepita-dialog';

import { AskToAgenciesDialog } from '../AskToAgenciesDialog';
import { ContentDialog } from '../ContentDialog';
import { DialogFooter } from '../DialogFooter';
import { SaveFromExistingConfirmDialog } from '../SaveFromExistingConfirmDialog';

import css from './styles.module.scss';

export function SaveSearchDialog({
  editSearch,
  onClose,
  onSuccess,
  entryPointPosition,
}: {
  editSearch: boolean;
  onClose: () => void;
  onSuccess?: () => void;
  entryPointPosition: SaveSearchProps['position'];
}) {
  const { trans } = useTranslations();
  const { trackTrovakasaFormSent, trackSearchSaveModalOpened } =
    useSaveSearchTracking();

  const domainName: ProductConfig['domainName'] =
    getProductConfig('domainName');

  const { form, askToAgencies, setAskToAgencies, searchID, alertInfoAuth } =
    useSaveSearchForm({
      editSearch,
      onClose,
      onSuccess,
      entryPointPosition,
    });

  const [
    isSaveFromExistingConfirmDialogVisible,
    setSaveFromExistingConfirmDialogVisible,
  ] = useState(false);
  const authModal = useAtomValue(authModalAtom);

  const handleAskToAgenciesClose = useCallback(() => {
    //  check if i needed to show the alert info dialog
    if (alertInfoAuth) {
      // Close the trovaKasa dialog and triggers the check for showing the alert info dialog.
      setAskToAgencies(false);
    } else {
      // close all dialog
      onClose();
    }
  }, [alertInfoAuth, onClose, setAskToAgencies]);

  const handleAskToAgenciesSuccess = useCallback(() => {
    const alertType = getAlertType(
      form.data.flagNotificationDaily || false,
      form.data.flagNotificationRealtime || false
    );

    trackTrovakasaFormSent(alertType);
    handleAskToAgenciesClose();
  }, [
    form.data.flagNotificationDaily,
    form.data.flagNotificationRealtime,
    trackTrovakasaFormSent,
    handleAskToAgenciesClose,
  ]);

  useEffect(() => {
    trackSearchSaveModalOpened(entryPointPosition === 'autoOpen', editSearch);
  }, [editSearch, entryPointPosition, trackSearchSaveModalOpened]);

  return (
    <>
      <PepitaDialog
        disableCloseOnBackdropClick
        size={PepitaDialogFrameType.BOTTOMSHEET}
        contentCustomClass={clsx(
          !alertInfoAuth && css['in-saveSearchDialog__content']
        )}
        title={
          editSearch
            ? trans('update_search_modal_title')
            : trans('act_save_search')
        }
        content={
          alertInfoAuth && !askToAgencies ? (
            <SniperLinkContent text={trans('check_email_activate_search')} />
          ) : (
            <ContentDialog
              form={form}
              editSearch={editSearch}
              entryPointPosition={entryPointPosition}
            />
          )
        }
        onClose={onClose}
        footer={
          alertInfoAuth && !askToAgencies && form.data.email ? (
            <SniperLinkButtonWrapper
              email={form.data.email}
              domainName={domainName}
              onClick={onClose}
            />
          ) : (
            <DialogFooter
              form={form}
              editSearch={editSearch}
              onSaveFromExistingClick={() => {
                setSaveFromExistingConfirmDialogVisible(true);
              }}
            />
          )
        }
        footerCustomClass={clsx(
          css['in-saveSearchDialog__footer'],
          'cy-saveSearchDialogFooter'
        )}
      />

      {askToAgencies && !authModal.open && searchID && (
        <AskToAgenciesDialog
          onClose={handleAskToAgenciesClose}
          searchID={searchID}
          onSuccess={handleAskToAgenciesSuccess}
        />
      )}

      {isSaveFromExistingConfirmDialogVisible && (
        <SaveFromExistingConfirmDialog
          onSubmit={form.handleSubmit}
          onClose={() => {
            setSaveFromExistingConfirmDialogVisible(false);
          }}
        />
      )}
    </>
  );
}
