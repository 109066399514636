import { isFeatureEnabled } from 'src/config/features-toggle';

import type { RegisterType } from 'src/types/auth';
import type {
  AskToAgenciesDialogData,
  SaveSearchData,
} from 'src/types/save-search';

import { http } from 'src/utils/client/http';
import { absoluteURL } from 'src/utils/url';
import { parseXMLResponse } from 'src/utils/xmlParser';
import { AttempsError, mapParamsToSaveSearchParams } from './utils';

const TROVAKASA_URL = '/eseguiServizioTrovakasa.php';

const realTimeNotificationsEnabled = isFeatureEnabled(
  'REALTIME_NOTIFICATIONS_ENABLED'
);

type TrovakasaResponse = {
  risposta: {
    success?: '1' | '0';
    dati: {
      user?: {
        nome: string;
        cognome: string;
        email: string;
        username: string;
      };

      register_type: RegisterType;
      count_real_time_alert: number;
      count_daily_alert: number;
      count_contacts_enabled: number;
    };
    instructions: {
      reload: '0' | '1';
    };
    tipo_servizio: string;
    ricerca_area: '0' | '1' | '2';
    idRicerca: string;
    errori?: { [field: string]: string };
  };
};

export const postAskToAgenciesDialog = (data: AskToAgenciesDialogData) => {
  const trovakasaData = {
    trova_immobili: 1,
    privacy_ricerca: data.privacy ? 'on' : 'off',
    ricevi: 'off',
    invia: 'on',
    idRicerca: data.searchID,
    email: data.email,
    nome: data.name,
    cognome: data.surname,
    telefono: data.phone,
    descrizioneImmobile: data.message,
    ...mapParamsToSaveSearchParams(data.searchParams),
  };

  return sendTrovaKasaDryRun(trovakasaData);
};

const getFlagNotificationRealtime = (
  realTimeNotificationsEnabled: boolean,
  data: SaveSearchData
) => {
  if (realTimeNotificationsEnabled) {
    if (data.flagNotificationRealtime) {
      return '1';
    }

    return '0';
  }

  return undefined;
};

const getflagNotificationDaily = (
  realTimeNotificationsEnabled: boolean,
  data: SaveSearchData
) => {
  if (realTimeNotificationsEnabled) {
    if (data.flagNotificationDaily) {
      return '1';
    }

    return '0';
  }

  return undefined;
};

const getFlagContactEnabled = (flagContactsEnabled?: boolean) => {
  if (flagContactsEnabled === undefined) {
    return undefined;
  }

  return flagContactsEnabled ? '1' : '0';
};

export const postSaveSearch = (data: SaveSearchData) => {
  // Getting trovakasa params
  const trovakasaData = {
    trova_immobili: 1,
    privacy_ricerca: data.privacy ? 'on' : 'off',
    ricevi: 'on',
    invia: 'off',
    isCheckRequest: 0,
    source: data.source,
    ...mapParamsToSaveSearchParams(data.searchParams),
    email: data.email,
  };

  trovakasaData['flagContactsEnabled'] = getFlagContactEnabled(
    data.flagContactsEnabled
  );

  if (realTimeNotificationsEnabled) {
    trovakasaData['flagNotificationRealtime'] = getFlagNotificationRealtime(
      realTimeNotificationsEnabled,
      data
    );
    trovakasaData['flagNotificationDaily'] = getflagNotificationDaily(
      realTimeNotificationsEnabled,
      data
    );
  }

  if (data.nome) {
    trovakasaData['nome'] = data.nome;
  }

  if (data.cognome) {
    trovakasaData['cognome'] = data.cognome;
  }

  if (data.telefono) {
    trovakasaData['telefono'] = data.telefono;
  }

  if (data.searchName) {
    trovakasaData['nomeRicerca'] = data.searchName;
  }

  if (data.searchID && data.action === 'update') {
    trovakasaData['idRicerca'] = data.searchID;
  }

  return sendTrovaKasa(trovakasaData);
};

export const sendTrovaKasa = (data) =>
  http
    .post(absoluteURL(TROVAKASA_URL), {
      form: {
        ...data,
        isCheckRequest: 0,
      },
      headers: {
        accept: 'application/xml, text/xml, */*; q=0.01',
      },
    })
    .raw()
    .then((response) => {
      const jsonResponse = parseXMLResponse(response.body) as TrovakasaResponse;

      if (
        jsonResponse.risposta.success === '1' &&
        jsonResponse.risposta.idRicerca
      ) {
        return {
          searchId: jsonResponse.risposta.idRicerca,
          userRegisterType: jsonResponse.risposta.dati.register_type,
          savedSearchesRealTimeAlert:
            jsonResponse.risposta.dati.count_real_time_alert,
          savedSearchesContactsEnabled:
            jsonResponse.risposta.dati.count_contacts_enabled,
        };
      } else if (
        jsonResponse.risposta.success === '0' &&
        jsonResponse?.risposta?.errori?.erroreTentativi === '1'
      ) {
        // I tried to save a search as a non-logged user more than two
        // times
        throw new AttempsError();
      } else {
        throw new Error(
          `Error with save search api: ${jsonResponse?.risposta?.errori}`
        );
      }
    });

const sendTrovaKasaDryRun = (data) =>
  http
    .post(absoluteURL(TROVAKASA_URL), {
      form: {
        ...data,
        isCheckRequest: 1,
      },
    })
    .raw()
    .then((response) => {
      const jsonResponse = parseXMLResponse(response.body) as TrovakasaResponse;

      if (jsonResponse.risposta.success === '1') {
        return sendTrovaKasa({ ...data, isCheckRequest: 0 });
      }

      throw new Error(
        `Error with save search api: ${jsonResponse?.risposta?.errori}`
      );
    });
