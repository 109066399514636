import type { LISTING_TYPE } from 'src/constants/real-estate';

export interface RawBasicUser {
  username: string;
  nome: string;
  cognome: string;
  telefono: string | null;
  phonePrefix: string;
  telefonoValidato: boolean;
  isUtentePhoneValidate: boolean;
  email: string;
  emailValidata: boolean;
  isFacebookUser: boolean;
  language: string;
  thumb: string | null;
  status: number;
  uuid: string;
}

export interface RawUser {
  appraisalHPUrl: string;
  appraisalUrl: string;
  blacklistedAdsUrl: string;
  cognome: string;
  countAppraisals: number | null;
  countBlacklistedAds: number | null;
  countLastAds: number | null;
  countLastSearches: number | null;
  countMyAds: number | null;
  countPublishedAds: number | null;
  countSavedAds: number | null;
  countSavedSearches: number | null;
  defaultLang: string;
  email: string;
  emailValidata: boolean;
  enabledNewsletter: boolean;
  enabledProductsEmail: boolean;
  enabledPromotionalEmail: boolean;
  error: [];
  isFacebookUser: boolean;
  isUtentePhoneValidate: boolean;
  language: string;
  lastAds: [];
  lastAdsUrl: string;
  lastSearches: [];
  loanPageUrl: string;
  messagesNotify: number | null;
  messagesUrl: string;
  myAds: [];
  nome: string;
  profileUrl: string;
  publishedAdsLandingUrl: string;
  publishedAdsUrl: string;
  publishNewAdUrl: string;
  savedAds: [];
  savedAdsUrl: string;
  savedSearches: [];
  savedSearchesUrl: string;
  sellerLeadUrl: string;
  status: number;
  telefono: string | null;
  telefonoValidato: boolean;
  thumb: null;
  username: string;
  uuid: string;
  phonePrefix: string | null;
}

export interface BasicUserResponse {
  uuid: string;
  email: string;
  name: string;
  surname: string;
  thumb: string | null;
  mailVerified: boolean;
  phoneVerified: boolean;
  phone: string | null;
  phone_prefix: string | null;
}

export interface UserResponse {
  cognome: string;
  countAppraisals: number;
  countBlacklistedAds: number;
  countLastAds: number;
  countLastSearches: number;
  countMyAds: number;
  countPublishedAds: number;
  countSavedAds: number;
  countSavedSearches: number;
  defaultCountry: string;
  defaultLang: string;
  email: string;
  emailValidata: boolean;
  enabledNewsletter: boolean;
  enabledProductsEmail: boolean;
  enabledPromotionalEmail: boolean;
  error: [];
  isFacebookUser: boolean;
  isUtentePhoneValidate: boolean;
  language: string;
  messagesNotify: number;
  nome: string;
  status: number;
  telefono: string | null;
  telefonoValidato: boolean;
  thumb: string | null;
  username: string;
  uuid: string;
  phonePrefix: string | null;
}

export interface UserRegistrationType {
  registerType: RegisterType;
  message: string;
}

export interface SuggestEmailResponse {
  error: string;
  suggestedEmails: string[];
}

export enum RegisterType {
  UNREGISTERED = 'UNREGISTERED',
  REGISTERED_WEAK_PWD = 'REGISTERED_WEAK_PWD',
  REGISTERED_CUSTOM_PWD = 'REGISTERED_CUSTOM_PWD',
}

export interface LoginBody {
  email: string;
  password: string;
  __lang: string;
}

export interface LoginData {
  name: string;
  surname: string;
  verified: string;
  phone: string;
  active: string;
  token: string;
}

export type LoginCookies = {
  IMMSESSID: string;
  PHPSESSID: string;
  imm_pl: string;
};

export interface LoginResponse {
  message: string;
  data: LoginData;
  cookies: LoginCookies;
}

export interface LogoutResponse {
  message: string;
}

export interface PasswordRetrieveRequestBody {
  email: string;
}

export interface PasswordRetrieveResponse {
  message: string;
  data: {
    email: string;
  };
}
export interface RegisterRequestBody {
  email: string;
  first_name: string;
  last_name: string;
  phone: string;
  ['phone-prefix']: string;
  password: string;
  privacy: number;
  communications: number;
  newsletter: number;
}

export interface UpdateUserBody {
  token: string;
  phone: string;
  'phone-cc': string;
}

export interface RegisterData {
  email: string;
  name: string;
  surname: string;
  phone: string;
  newsletter: boolean;
  privacy: number;
  communications: number;
  skipLogin: boolean;
  uuid: string;
}

export interface RegisterRequestResponse {
  message: string;
  data: RegisterData;
}

export type AuthLandingData =
  | BaseLandingData
  | SaveSearchData
  | SaveListingData
  | MessagingData;

export enum ACTION_TYPE {
  SAVE_LISTING = 'saveListing',
  SAVE_SEARCH = 'saveSearch',
  MESSAGING = 'messaging',
  DEFAULT = 'withoutAction',
}

export interface CommonData {
  ctaToken: string;
  email: string;
  redirectUrl: string;
  registerType: RegisterType;
}
export interface BaseLandingData extends CommonData {
  action: ACTION_TYPE.DEFAULT;
}

export interface SaveSearchData extends CommonData {
  action: ACTION_TYPE.SAVE_SEARCH;
  savedSearchToken: string;
}

export interface SaveListingData extends CommonData {
  action: ACTION_TYPE.SAVE_LISTING;
  realEstateId: number;
  realEstateType: LISTING_TYPE;
}

export interface MessagingData extends CommonData {
  action: ACTION_TYPE.MESSAGING;
  name: string;
}
