import { useMemo } from 'react';
import { useAuthContext } from '@indomita-react/auth-provider';
import { useTranslations } from '@pepita-react/i18n';

import { isFeatureEnabled } from 'src/config/features-toggle';

import { useGrowthBookFeature } from 'src/libs/growthbook/client';

import { frequencySearchSettingsDefault, getAlertMessage } from '../utils';

interface FrequencySectionData {
  realTimeAlerts: number;
  listingsResultsCount: number;
  dailyResultsCountTransKey: string;
  realTimeResultsCountTransKey: string;
  editSearch?: boolean;
  url: string;
}

export function useFrequencySectionData({
  realTimeAlerts,
  listingsResultsCount,
  dailyResultsCountTransKey,
  realTimeResultsCountTransKey,
  url,
}: FrequencySectionData) {
  const { user } = useAuthContext();
  const { trans } = useTranslations();

  const frequencySettings = useGrowthBookFeature(
    'search_alert_frequency_settings',
    frequencySearchSettingsDefault
  );

  const maxRealTimeSavedSearches = frequencySettings.realTime.maxSearches;
  const isSaveSearchLimitEnabled =
    user && isFeatureEnabled('SAVE_SEARCH_OPTIONS_LIMITS');

  const isListingsResultsLimitEnabled =
    user && isFeatureEnabled('DISABLE_ALERTS_FOR_HIGH_RESULT_SEARCHES');

  const isRealTimeAlertsLimitExceeded = isSaveSearchLimitEnabled
    ? realTimeAlerts >= maxRealTimeSavedSearches
    : false;

  const isRealTimeResultsCountExceeded = isListingsResultsLimitEnabled
    ? listingsResultsCount >= frequencySettings.realTime.maxResults
    : false;

  const isDailyResultsCountExceeded = isListingsResultsLimitEnabled
    ? listingsResultsCount >= frequencySettings.daily.maxResults
    : false;

  const counterHeader = isSaveSearchLimitEnabled
    ? `(${realTimeAlerts}/${maxRealTimeSavedSearches})`
    : undefined;

  const realTimeErrorMessage = useMemo(() => {
    const realTimeConditions = [
      {
        condition: isRealTimeAlertsLimitExceeded,
        transKey: 'lbl_notifications_frequency_alert',
        transParams: [
          `<span>${realTimeAlerts}</span>`,
          `<a href=${url}>`,
          '</a>',
        ],
      },
      {
        condition: isRealTimeResultsCountExceeded,
        transKey: realTimeResultsCountTransKey,
        transParams: [frequencySettings.realTime.maxResults],
      },
    ];

    return getAlertMessage(trans, realTimeConditions);
  }, [
    isRealTimeAlertsLimitExceeded,
    realTimeAlerts,
    url,
    isRealTimeResultsCountExceeded,
    realTimeResultsCountTransKey,
    frequencySettings.realTime.maxResults,
    trans,
  ]);

  const dailyErrorMessage = useMemo(() => {
    const dailyConditions = [
      {
        condition: isDailyResultsCountExceeded,
        transKey: dailyResultsCountTransKey,
        transParams: [frequencySettings.daily.maxResults],
      },
    ];

    return getAlertMessage(trans, dailyConditions);
  }, [
    dailyResultsCountTransKey,
    frequencySettings.daily.maxResults,
    isDailyResultsCountExceeded,
    trans,
  ]);

  const isRealTimeNotificationFlagBlocked =
    isRealTimeAlertsLimitExceeded || isRealTimeResultsCountExceeded;

  return {
    counterHeader,
    realTimeErrorMessage,
    dailyErrorMessage,
    isRealTimeNotificationFlagBlocked,
    isDailyNotificationFlagBlocked: isDailyResultsCountExceeded,
  };
}
