import { useTranslations } from '@pepita-react/i18n';

import { PepitaButton } from 'src/libs/ui/pepita-button';
import { PepitaDialog, PepitaDialogFrameType } from 'src/libs/ui/pepita-dialog';

interface PreviousSearchAlertDialogProps {
  onClose: () => void;
  onConfirm: () => void;
}

export function PreviousSearchAlertDialog({
  onClose,
  onConfirm,
}: PreviousSearchAlertDialogProps) {
  const { trans } = useTranslations();

  return (
    <PepitaDialog
      title={trans('act_save_search')}
      onClose={onClose}
      size={PepitaDialogFrameType.DIALOG}
      content={trans('save_previous_search_modal_text')}
      footer={
        <>
          <PepitaButton onClick={onClose}>
            {trans('act_cancel', { capitalize: true })}
          </PepitaButton>
          <PepitaButton variant="accent" onClick={onConfirm}>
            {trans('act_confirm', { capitalize: true })}
          </PepitaButton>
        </>
      }
    />
  );
}
