import React from 'react';

import { NotificationOptions } from 'src/components/SaveSearch/NotificationOptions';

import { PepitaSkeleton } from 'src/libs/ui/pepita-skeleton';

import css from './styles.module.scss';

export function SkeletonReceiveContactsSection() {
  return (
    <NotificationOptions>
      <div className={css['in-skeletonReceiveContactsHeading']}>
        <PepitaSkeleton type="row" theme="light" items={1} />
        <PepitaSkeleton
          type="text"
          theme="light"
          items={1}
          customClass={css['in-skeletonReceiveContactsHeading__title']}
        />
        <PepitaSkeleton
          type="text"
          theme="light"
          items={1}
          customClass={css['in-skeletonReceiveContactsHeading__tooltip']}
        />
      </div>
      <PepitaSkeleton type="text" theme="light" items={2} />
    </NotificationOptions>
  );
}
