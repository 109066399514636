import { useTranslations } from '@pepita-react/i18n';

import type { AskToAgenciesDialogData } from 'src/types/save-search';

import type { ValidationSchema } from 'src/utils/form-validators';
import {
  isEmail,
  isPhoneNumber,
  isRequired,
  isTruthy,
} from 'src/utils/form-validators';
import { capitalize } from 'src/utils/string';

export function useAskToAgenciesDialogSchema(): ValidationSchema<AskToAgenciesDialogData> {
  const { trans } = useTranslations();

  return {
    message: [
      (data: AskToAgenciesDialogData) => {
        const cleanedMessage = data.message
          ? data.message.trim().replace(/\s\s+/g, ' ')
          : null;

        return {
          valid: cleanedMessage && cleanedMessage.split(' ').length >= 10,
          message: trans('ask_to_agencies_modal_message_error'),
        };
      },
    ],
    privacy: [isTruthy(trans('act_declare_consent'))],
    name: [
      isRequired<AskToAgenciesDialogData>(
        capitalize(trans('name_field_validation_msg'))
      ),
    ],
    surname: [
      isRequired<AskToAgenciesDialogData>(
        capitalize(trans('surname_field_validation_msg'))
      ),
    ],
    email: [
      isRequired<AskToAgenciesDialogData>(
        capitalize(trans('insert_valid_email'))
      ),
      isEmail<AskToAgenciesDialogData>(capitalize(trans('insert_valid_email'))),
    ],
    phone: [
      isRequired<AskToAgenciesDialogData>(
        capitalize(trans('phone_field_validation_msg'))
      ),
      isPhoneNumber<AskToAgenciesDialogData>(
        capitalize(trans('phone_field_validation_msg'))
      ),
    ],
  };
}
