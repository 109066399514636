import alternateLocationPreview2x from 'src/images/ricercasalvata_placeholder@2x.png';

import { getProductConfig } from 'src/config/product';

import type { GeographySearch } from 'src/libs/geography';

import type { staticMapOptionType } from 'src/utils/staticMapImage';
import { buildStaticMapImageUrl } from 'src/utils/staticMapImage';

export const getAlternateLocationPreviewUrl = (): string =>
  alternateLocationPreview2x.src;

export const getCityLocationPreviewUrl = (
  cityID: string,
  resolution: string,
  scale: string
): string =>
  `${getProductConfig('vhostUrlCdnMaps')}${getProductConfig(
    'mapchartImgServiceVersion'
  )}/${resolution}/${scale}/${cityID}`;

export const getCityZoneLocationPreviewUrl = (
  cityID: Optional<string>,
  resolution: string,
  scale: string,
  macrozonesIDs: string[]
): string =>
  cityID
    ? `${getCityLocationPreviewUrl(
        cityID,
        resolution,
        scale
      )}/${macrozonesIDs.join('-')}`
    : getAlternateLocationPreviewUrl();

export const getDrawingLocationPreviewUrl = (
  drawing: GeographySearch,
  width: number,
  height: number
): Nullable<string> => {
  const options: staticMapOptionType = {
    width,
    height,
    markerType: 3,
    style: 'feature:road|element:labels|visibility:off',
    fillColor: '0x0074c14d',
    strokeColor: '0x0074c1ff',
    strokeWeight: 2,
  };

  if (drawing.searchType === 'circle') {
    options.markerType = 2;
    options.radius = drawing.value.radius;

    return buildStaticMapImageUrl(
      parseFloat(drawing.value.center[0] as string),
      parseFloat(drawing.value.center[1] as string),
      0,
      options
    );
  } else if (drawing.searchType === 'polygon') {
    options.path = drawing.value.points;

    return buildStaticMapImageUrl(0, 0, 0, options);
  }

  return null;
};
