import { useMemo } from 'react';
import { useBreakpoint } from '@pepita-react/use-breakpoint';

import type { fieldConfigType } from '../config/formConfigTypes';

import { useDetectHiddenFields } from './useDetectHiddenFields';

export const useGetBaseFilters = (stateAtom) => {
  // This works because this hook is called only client-side
  const [maxScreenWidthSm] = useBreakpoint({ to: 'sm' });
  const [screenWidthFromSmToMd] = useBreakpoint({ from: 'sm', to: 'md' });
  const [screenWidthFromMdToLg] = useBreakpoint({ from: 'md', to: 'lg' });

  const visibleFilters = useDetectHiddenFields(stateAtom);

  const baseFilterConfigs = useMemo(() => {
    return visibleFilters.filter((field) => Boolean(field.topFilter));
  }, [visibleFilters]);

  // Filters that should go on the form bar
  const barFilters = useMemo(() => {
    const result: fieldConfigType[] = [];

    switch (true) {
      case maxScreenWidthSm:
        // Show nothing on the bar (all filters are inside the modal)
        break;
      case screenWidthFromSmToMd: {
        // From 768 to 1023
        result.push(baseFilterConfigs[0]);
        break;
      }

      case screenWidthFromMdToLg: {
        // From 1024 to 1279
        result.push(baseFilterConfigs[0], baseFilterConfigs[1]);
        break;
      }

      default: {
        // When size is greater than 1280 we show everything
        result.push(...baseFilterConfigs);
        break;
      }
    }

    return result;
  }, [
    maxScreenWidthSm,
    screenWidthFromSmToMd,
    screenWidthFromMdToLg,
    baseFilterConfigs,
  ]);

  return {
    barFilters,
    modalFilters: baseFilterConfigs,
  };
};
