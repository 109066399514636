import { useEffect, useRef } from 'react';

import { useActiveSearch } from '../hooks/useActiveSearch';

import { getSaveSearchSectionUrl } from '../utils';

import type { ToggleSwitchValue } from '../ReceiveContactsSection';
import { ReceiveContactsSection } from '../ReceiveContactsSection';

type ActiveSearchSectionProps = {
  handleChange: (update: (data: ToggleSwitchValue) => void) => void;
  toggleValue?: boolean;
  editSearch: boolean;
};

export function ActiveSearchSection({
  handleChange,
  toggleValue,
  editSearch,
}: ActiveSearchSectionProps) {
  const url = getSaveSearchSectionUrl(
    editSearch ? 'update-search' : 'save-search',
    'active'
  );

  const {
    alertMessage,
    areaSqm,
    flagContactsEnabled,
    maxSavedSearches,
    activeSearches,
  } = useActiveSearch(url);

  const flagContactsEnabledSettled = useRef(false);

  useEffect(() => {
    // to prevent infinite loop when flagContactsEnabled changes
    if (areaSqm === null || flagContactsEnabledSettled.current) {
      return;
    }

    handleChange((data) => {
      data.flagContactsEnabled = flagContactsEnabled;
    });

    flagContactsEnabledSettled.current = true;
  }, [flagContactsEnabled, areaSqm, handleChange]);

  return (
    <ReceiveContactsSection
      value={{
        flagContactsEnabled: toggleValue,
      }}
      handleChange={handleChange}
      showFlagContactsField={flagContactsEnabled !== undefined}
      errorMessage={alertMessage}
      activeSearches={activeSearches}
      maxSavedSearches={maxSavedSearches}
    />
  );
}
