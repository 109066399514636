/**
 * Get a string representing an xml resource and returns
 * the corresponding json structure
 * @param data
 */
export const parseXMLResponse = (data: string) => {
  // Removing empty spaces between tags
  const cleanedData = data.replace(/>\s+</g, '><');
  const parser = new DOMParser();
  const parsedDom = parser.parseFromString(cleanedData, 'text/xml');
  const walker = document.createTreeWalker(parsedDom);

  walker.nextNode();

  return traverseDom(walker);
};

const traverseDom = (walker: TreeWalker) => {
  const result = {};
  const currentElement = walker.currentNode as HTMLElement;

  if (currentElement) {
    // Getting values from attributes
    // e.g. <result success="1">...</result> will
    // generate the following entry
    // ---- { result: {success: "1"} } ----
    const attributes = currentElement.attributes;

    if (attributes) {
      result[currentElement.tagName] = {};
      for (let i = 0; i < attributes.length; i++) {
        result[currentElement.tagName][attributes[i].name] =
          attributes[i].value;
      }
    }

    // Getting values from children using recursion
    // e.g <result><data>2</data></result>
    // will generate the following entry
    // ---- { result: {data: 2} } ----
    let nextNode = walker.firstChild();

    while (nextNode) {
      // Base case for recursion, when we have
      // a text node we can return the node value
      if (nextNode.nodeType === Node.TEXT_NODE) {
        return nextNode.nodeValue;
      }

      // We recursively traverse the dom from this
      // child returning values extracted from the
      // subtree
      const subtreeResult = traverseDom(document.createTreeWalker(nextNode));

      // Merging results taken from the subtree
      if (typeof subtreeResult !== 'object') {
        result[currentElement.tagName] = {
          ...result[currentElement.tagName],
          [(nextNode as HTMLElement).tagName]: subtreeResult,
        };
      } else {
        result[currentElement.tagName] = {
          ...result[currentElement.tagName],
          ...subtreeResult,
        };
      }

      nextNode = walker.nextSibling();
    }
  }

  return result;
};
