import { useEffect } from 'react';
import { atomWithReset, useResetAtom } from 'jotai/utils';

export type SearchMethod = 'AREA' | 'DISTANCE';

export const searchOnMapAtom = atomWithReset<SearchMethod | false>(false);

export const useResetSearchOnMapAtom = () => {
  const resetSearchOnMap = useResetAtom(searchOnMapAtom);

  useEffect(() => {
    resetSearchOnMap();
  }, [resetSearchOnMap]);
};
