import { getProductConfig } from 'src/config/product';

import { serializeIntoUrl } from 'src/utils/querystring';

import mapMarker from '../images/map-marker.png';

export type staticMapOptionType = {
  width?: number;
  height?: number;
  // 1-> marker 2 -> circle 3 -> polygon
  markerType?: 1 | 2 | 3;
  language?: string;
  strokeColor?: string;
  fillColor?: string;
  strokeWeight?: number;
  style?: string;
  scale?: number;
  // in meters
  radius?: number;
  path?: number[][];
  numPoints?: number;
};

const createCirclePath = (
  lat: number,
  lng: number,
  radius: number,
  numPoints: number
): string => {
  const degreesToRadiants = Math.PI / 180;
  let circlePath = '';
  const center = { lat, lng };

  // Convert statute Km into degrees latitude
  const circleLat = radius / 111;
  const circleLng = circleLat / Math.cos(center.lat * degreesToRadiants);

  // Create polygon points (extra point to close polygon)
  for (let i = 0; i < numPoints + 1; i++) {
    // Convert degrees to radians
    const theta = Math.PI * (i / (numPoints / 2));
    const vertexLat = center.lat + circleLat * Math.sin(theta);
    const vertexLng = center.lng + circleLng * Math.cos(theta);

    circlePath += `|${vertexLat},${vertexLng}`;
  }

  return circlePath;
};

const createPolygonPath = (path: number[][], numPoints: number) => {
  let polygonPath = '';

  let step = 1;

  if (path.length > numPoints) {
    step = Math.round(path.length / numPoints);
  }

  for (let i = 0; i < path.length; i = i + step) {
    let vertexLat = path[i][0];
    let vertexLng = path[i][1];

    polygonPath += `|${vertexLat},${vertexLng}`;
    if (i > path.length - step * 2) {
      vertexLat = path[0][0];
      vertexLng = path[0][1];
      polygonPath += `|${vertexLat},${vertexLng}`;
      break;
    }
  }

  return polygonPath;
};

export const buildStaticMapImageUrl = (
  lat: number,
  lng: number,
  zoom: number,
  options: staticMapOptionType
) => {
  if (
    typeof lat === 'undefined' ||
    typeof lng === 'undefined' ||
    typeof zoom === 'undefined'
  ) {
    throw new Error('Missing center and zoom');
  }

  const basePath = getProductConfig('staticMapUrl');

  options.width = options.width || 628;
  options.height = options.height || 245;
  options.language = options.language || 'it';
  options.scale = options.scale || 1;
  options.fillColor = options.fillColor || '0x319ACF';
  options.strokeColor = options.strokeColor || '0x319ACF';
  options.strokeWeight = options.strokeWeight || 2;
  options.numPoints = options.numPoints || 40;

  options.radius = options.radius || 250; // meters
  options.radius = options.radius / 1000; // Kilometers

  const queryParamsStaticMap: {
    [key: string]: string | number | boolean | undefined;
  } = {
    zoom: zoom && zoom > 0 ? zoom : undefined,
    size: `${options.width}x${options.height}`,
    language: options.language,
    style: options.style,
    scale: options.scale !== 1 ? options.scale : undefined,
    sensor: false,
  };

  let markerString = '';

  switch (options.markerType) {
    case 3:
      markerString += `color:${options.strokeColor}|fillcolor:${options.fillColor}|weight:${options.strokeWeight}`;
      markerString += createPolygonPath(options.path, options.numPoints);
      queryParamsStaticMap.path = markerString;
      break;
    case 2:
      markerString += `color:${options.strokeColor}|fillcolor:${options.fillColor}|weight:${options.strokeWeight}`;
      markerString += createCirclePath(
        lat,
        lng,
        options.radius,
        options.numPoints
      );
      queryParamsStaticMap.path = markerString;
      break;
    case 1:
      queryParamsStaticMap.markers = `icon:${mapMarker.src}|${lat},${lng}`;
      queryParamsStaticMap.center = `${lat},${lng}`;
      break;
    default:
      queryParamsStaticMap.center = `${lat},${lng}`;
      break;
  }

  return serializeIntoUrl(basePath, queryParamsStaticMap);
};
