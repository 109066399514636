import { useAuthContext } from '@indomita-react/auth-provider';
import { useTranslations } from '@pepita-react/i18n';

import type { SaveSearchData } from 'src/types/save-search';

import type { ValidationSchema } from 'src/utils/form-validators';
import { isEmail, isRequired, isTruthy } from 'src/utils/form-validators';
import { capitalize } from 'src/utils/string';

export function useSaveSearchDialogSchema(
  editSearch?: boolean
): ValidationSchema<SaveSearchData> {
  const { trans } = useTranslations();
  const { user } = useAuthContext();

  const schema = {
    message: [
      () => {
        return {
          valid: true,
          message: '',
        };
      },
    ],
    searchName: [
      (data: SaveSearchData) => {
        if (!data.searchName || data.searchName === '') {
          return {
            valid: true,
            message: '',
          };
        }

        return {
          valid: data.searchName.length >= 5,
          message: trans('alert_email_title_validation'),
        };
      },
    ],
  };

  if (user || editSearch) {
    // The validated fields are passed through UserData when the user is authenticated
    return schema;
  }

  return {
    ...schema,
    privacy: [isTruthy(trans('act_declare_consent'))],
    email: [
      (data: SaveSearchData) => {
        if (!data.email) {
          return isRequired<SaveSearchData>(
            trans('email_field_validation_msg')
          )(data, 'email');
        }

        return {
          valid: true,
          message: '',
        };
      },
      isEmail(capitalize(trans('insert_valid_email'))),
    ],
  };
}
