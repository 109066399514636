import { PepitaBadge } from 'src/libs/ui/pepita-badge';
import { PepitaIcon } from 'src/libs/ui/pepita-icon';
import { PepitaTooltip } from 'src/libs/ui/pepita-tooltip';

import css from './styles.module.scss';

interface NotificationHeadingProps {
  icon: string;
  title: string;
  tooltipText: string;
  counter?: string;
  badgeLabel?: string;
}

export function NotificationHeading({
  icon,
  title,
  tooltipText,
  badgeLabel,
  counter,
}: NotificationHeadingProps) {
  return (
    <div className={css['in-saveSearchDialogNotificationHeading']}>
      <PepitaIcon name={icon} />
      <p>{title}</p>
      {badgeLabel && (
        <PepitaBadge variant="promotion">{badgeLabel}</PepitaBadge>
      )}

      <div className={css['in-saveSearchDialogNotificationHeading__right']}>
        {counter && (
          <span
            className={css['in-saveSearchDialogNotificationHeading__counter']}
          >
            {counter}
          </span>
        )}

        <PepitaTooltip
          label={
            <PepitaIcon
              name="info-circle"
              customClass={css['in-saveSearchDialogNotificationHeading__info']}
            />
          }
          dataText={tooltipText}
          multiRow
          type="dark"
          direction="toTop"
          customClass={css['in-saveSearchDialogNotificationHeading__tooltip']}
        />
      </div>
    </div>
  );
}
