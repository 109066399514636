import { useEffect, useMemo } from 'react';

import {
  createAtomicStateDerivedAtom,
  useAtomicStateAtomValue,
  useAtomicStateStateAtom,
} from 'src/atoms/atomic-state';
import { formConfigAtom, formStateAtom, searchModalStateAtom } from '../atoms';

import type {
  fieldConfigType,
  formConfigType,
} from '../config/formConfigTypes';

import type { formStateRefType, FormStateType } from '../types';

import { produce } from 'src/utils/immer';
import { deepEqual } from 'src/utils/object';
import { getDefaultHiddenFieldValue } from '../utils/formStateHelpers';
import { getHiddenFieldsFromState } from '../utils/getHiddenFieldsFromState';

export const hiddenFieldsAtom = createAtomicStateDerivedAtom({
  key: 'SearchFormHiddenFields',
  get({ get }) {
    const searchFormState = get(formStateAtom);
    const formConfig: formConfigType = get(formConfigAtom);

    return getHiddenFieldsFromState(searchFormState, formConfig);
  },
});

export const hiddenModalFieldsAtom = createAtomicStateDerivedAtom({
  key: 'SearchModalHiddenFields',
  get({ get }) {
    const searchFormState = get(searchModalStateAtom);
    const formConfig: formConfigType = get(formConfigAtom);

    return getHiddenFieldsFromState(searchFormState, formConfig);
  },
});

export const getHiddenFields = (stateAtom: formStateRefType) => {
  switch (true) {
    case stateAtom === searchModalStateAtom:
      return hiddenModalFieldsAtom;
    case stateAtom === formStateAtom:
    default:
      return hiddenFieldsAtom;
  }
};

const isNotFieldEmpty = (
  fieldConfig: fieldConfigType,
  state: FormStateType
) => {
  if (fieldConfig.type === 'range') {
    return (
      state[fieldConfig.fromName] !== null || state[fieldConfig.toName] !== null
    );
  }

  return !deepEqual(
    state[fieldConfig.field],
    getDefaultHiddenFieldValue(fieldConfig)
  );
};

const setEmptyField = (fieldConfig: fieldConfigType, state: FormStateType) => {
  if (fieldConfig.type === 'range') {
    state[fieldConfig.fromName] = null;
    state[fieldConfig.toName] = null;
  } else {
    state[fieldConfig.field] = getDefaultHiddenFieldValue(fieldConfig);
  }

  return state;
};

/**
 * Returns only visible fields
 */
export const useDetectHiddenFields = (
  stateAtom: formStateRefType
): fieldConfigType[] => {
  const [state, setState] = useAtomicStateStateAtom(stateAtom);

  const hiddenFields = useAtomicStateAtomValue(getHiddenFields(stateAtom));
  const formConfig = useAtomicStateAtomValue<formConfigType>(formConfigAtom);

  useEffect(() => {
    hiddenFields.forEach((fieldConfig) => {
      // When field is hidden and its value is not the default one
      // we erase the content
      if (isNotFieldEmpty(fieldConfig, state)) {
        setState((prevState) => {
          return produce(prevState, (draft) =>
            setEmptyField(fieldConfig, draft)
          );
        });
      }
    });

    // Find and reset constant filters which are now visible
    formConfig
      .filter((fieldConfig) => fieldConfig.type === 'hidden')
      .forEach((constField) => {
        if (
          !hiddenFields.find(
            (hiddenField) => hiddenField?.field === constField.field
          )
        ) {
          setState((prevState) => {
            return produce(prevState, (draft) => {
              draft[constField.field] = constField['value'];
            });
          });
        }
      });
  }, [formConfig, hiddenFields, setState, state]);

  return useMemo(
    () =>
      formConfig.filter(
        (fieldConfig) =>
          !hiddenFields.find(
            (hiddenField) => hiddenField?.field === fieldConfig.field
          )
      ),
    [formConfig, hiddenFields]
  );
};
