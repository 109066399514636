export enum ENERGY_RATE {
  high = '2',
  medium = '5',
  low = '8',
  all = '',
}

export enum ENERGY_RATE_KEYS {
  HIGH = 'high',
  MEDIUM = 'medium',
  LOW = 'low',
  ALL = 'all',
}

export type EnergyRateType = keyof typeof ENERGY_RATE;
