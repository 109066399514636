import React from 'react';
import clsx from 'clsx';

import { PepitaAlert } from 'src/libs/ui/pepita-alert';

import css from './styles.module.scss';

interface AlertErrorProps {
  message: string | null;
  show: boolean;
}

function AlertError({ show, message }: AlertErrorProps) {
  if (!message) return null;

  return (
    <PepitaAlert
      variant="error"
      customClass={clsx(css['in-alertError'], !show && css['is-hidden'])}
    >
      <p
        className={css['in-alertError__message']}
        dangerouslySetInnerHTML={{
          __html: message,
        }}
      />
    </PepitaAlert>
  );
}

export default AlertError;
