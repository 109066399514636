import { PrivacyCheckbox } from '@indomita-react/privacy-checkbox';
import { useTranslations } from '@pepita-react/i18n';
import { clsx } from 'clsx';

import { useFocusRef } from 'src/hooks/useFocusRef';
import { useAskToAgenciesForm } from '../hooks/useAskToAgenciesForm';

import { PepitaButton } from 'src/libs/ui/pepita-button';
import {
  PepitaDialog,
  PepitaDialogContentFrameType,
  PepitaDialogFrameType,
} from 'src/libs/ui/pepita-dialog';
import { PepitaTextField } from 'src/libs/ui/pepita-text-field';

import { capitalize } from 'src/utils/string';

import css from './styles.module.scss';

export function AskToAgenciesDialog({
  onClose,
  onSuccess,
  searchID,
}: {
  onClose: () => void;
  onSuccess?: () => void;
  searchID: string;
}) {
  const { trans } = useTranslations();
  const textareaRef = useFocusRef<HTMLTextAreaElement>(null);

  const { form } = useAskToAgenciesForm({
    onSuccess,
    searchID,
  });

  if (!searchID) return null;

  return (
    <PepitaDialog
      disableCloseOnBackdropClick
      frameCustomClass={css['in-askToAgenciesDialog']}
      size={PepitaDialogFrameType.BOTTOMSHEET}
      contentCustomClass={clsx(
        css['in-askToAgenciesDialog__content'],
        'nd-dialogFrame__content--scrollable'
      )}
      contentSize={PepitaDialogContentFrameType.FULL}
      title={trans('ask_to_agencies_title')}
      content={
        <>
          <div className={'nd-form'} data-cy={'ask-to-agencies'}>
            <span className={'nd-form__text'}>
              {trans('ask_to_agencies_description_text')}
            </span>

            <PepitaTextField
              textarea
              placeholder={trans('ask_to_agencies_message_placeholder')}
              rows={4}
              defaultValue={form.data.message || ''}
              onChange={form.handleTextInputChange('message')}
              status={form.getErrorMessage('message') ? 'error' : undefined}
              statusMessage={form.getErrorMessage('message')}
              ref={textareaRef}
            />

            <PepitaTextField
              floatingLabel
              placeholder={capitalize(trans('lbl_name', { capitalize: true }))}
              status={form.getErrorMessage('name') ? 'error' : undefined}
              statusMessage={form.getErrorMessage('name')}
              onChange={form.handleTextInputChange('name')}
              defaultValue={form.data.name}
            />
            <PepitaTextField
              floatingLabel
              placeholder={capitalize(trans('lbl_surname'))}
              status={form.getErrorMessage('surname') ? 'error' : undefined}
              statusMessage={form.getErrorMessage('surname')}
              onChange={form.handleTextInputChange('surname')}
              defaultValue={form.data.surname}
            />
            <PepitaTextField
              type="email"
              floatingLabel
              placeholder={trans('lbl_email')}
              status={form.getErrorMessage('email') ? 'error' : undefined}
              statusMessage={form.getErrorMessage('email')}
              onBlur={form.handleValidateTextInput('email')}
              onChange={form.handleTextInputChange('email')}
              defaultValue={form.data.email}
            />
            <PepitaTextField
              type="tel"
              floatingLabel
              placeholder={trans('lbl_phone')}
              status={form.getErrorMessage('phone') ? 'error' : undefined}
              statusMessage={form.getErrorMessage('phone')}
              onBlur={form.handleValidateTextInput('phone')}
              onChange={form.handleTextInputChange('phone')}
              defaultValue={form.data.phone}
            />
          </div>
          {/* footer */}
          <div
            className={clsx(
              css['in-askToAgenciesDialog__footer'],
              css['in-askToAgenciesDialog__footerNotLogged']
            )}
          >
            <PrivacyCheckbox
              error={form.getErrorMessage('privacy')}
              onChange={form.handleCheckboxChange('privacy')}
              value={Boolean(form.data.privacy)}
            />
            <div className={css['in-askToAgenciesDialog__actions']}>
              <PepitaButton
                type="submit"
                onClick={onClose}
                data-cy="ask-to-agencies-close"
              >
                {trans('no_thank_you')}
              </PepitaButton>
              <PepitaButton
                type="submit"
                variant="accent"
                onClick={form.handleSubmit}
                disabled={form.submitButtonDisabled}
                loading={form.submitButtonLoading}
              >
                {trans('act_send')}
              </PepitaButton>
            </div>
          </div>
        </>
      }
      onClose={onClose}
    />
  );
}
