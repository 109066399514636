import type { ReactNode } from 'react';

import css from './styles.module.scss';

export function NotificationOptions({
  children,
  dataCy,
}: {
  dataCy?: string;
  children: ReactNode;
}) {
  return (
    <div
      className={css['in-saveSearchDialogNotificationOptions']}
      data-cy={dataCy}
    >
      {children}
    </div>
  );
}
