import { useMemo } from 'react';

import type { AtomicStateAtomRef } from 'src/atoms/atomic-state';
import {
  createAtomicStateDerivedAtom,
  useAtomicStateAtomValue,
} from 'src/atoms/atomic-state';

export function useAtomSelector<V>(atom: AtomicStateAtomRef<any>, key: string) {
  const selector = useMemo(() => {
    const path = key.split('.');

    const selectorFn = (state: any) => {
      for (let i = 0; i < path.length; i++) {
        state = state ? state[path[i]] : null;
      }

      return state;
    };

    return createAtomicStateDerivedAtom({
      key: `#${atom.key}.${key}`,
      get({ get }) {
        return selectorFn(get(atom));
      },
    });
  }, [atom, key]);

  return useAtomicStateAtomValue(selector) as V;
}
