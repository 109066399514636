import type { DependencyList } from 'react';
import { useMemo, useRef } from 'react';

import { deepEqual } from 'src/utils/object';

const useDeepEqualMemoization = (dependencies: DependencyList) => {
  const dependenciesRef = useRef<DependencyList>(dependencies);

  if (!deepEqual(dependencies, dependenciesRef.current)) {
    dependenciesRef.current = dependencies;
  }

  return dependenciesRef.current;
};

export const useDeepEqualMemo = <T>(
  factory: () => T,
  dependencies: DependencyList
) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useMemo(factory, useDeepEqualMemoization(dependencies));
};
