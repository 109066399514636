import type { ChangeEvent } from 'react';
import { useCallback, useState } from 'react';
import { useTranslations } from '@pepita-react/i18n';

import AlertError from '../AlertError';
import { NotificationOptions } from '../NotificationOptions';
import { NotificationHeading } from '../NotificationOptions/NotificationHeading';
import { ToggleSwitch } from '../NotificationOptions/ToggleSwitch';

type ToggleSwitchValue = {
  flagNotificationDaily: boolean;
  flagNotificationRealtime: boolean;
};

interface FrequencySectionProps {
  value: ToggleSwitchValue;
  dailyErrorMessage: string | null;
  realTimeErrorMessage: string | null;
  isRealTimeNotificationFlagBlocked: boolean;
  isDailyNotificationFlagBlocked: boolean;
  handleChange: (update: (data: ToggleSwitchValue) => void) => void;
  counterHeader?: string;
}

export function FrequencySection({
  value,
  handleChange,
  counterHeader,
  isRealTimeNotificationFlagBlocked,
  realTimeErrorMessage,
  dailyErrorMessage,
  isDailyNotificationFlagBlocked,
}: FrequencySectionProps) {
  const { trans } = useTranslations();
  const [alertMessagesVisibility, setAlertMessagesVisibility] = useState<
    'realTime' | 'daily' | null
  >(null);

  const handleRealTimeChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const isChecked = event.target.checked;

      if (
        isRealTimeNotificationFlagBlocked &&
        isChecked &&
        realTimeErrorMessage
      ) {
        /**
         *  Show switch activation for 200ms before deactivating it.
         * This allows the user to see the switch turn on before it is turned off automatically
         *  */
        setTimeout(() => {
          setAlertMessagesVisibility('realTime');
          handleChange((data) => {
            data.flagNotificationRealtime = false;
            data.flagNotificationDaily = false;
          });
        }, 200);
      }

      handleChange((data) => {
        data.flagNotificationRealtime = isChecked;
        if (isChecked) {
          data.flagNotificationDaily = false;
        }
      });
    },
    [isRealTimeNotificationFlagBlocked, realTimeErrorMessage, handleChange]
  );

  const handleDailyChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const isChecked = event.target.checked;

      if (isChecked) {
        if (isDailyNotificationFlagBlocked && dailyErrorMessage) {
          /**
           *  Show switch activation for 200ms before deactivating it.
           * This allows the user to see the switch turn on before it is turned off automatically
           *  */
          setTimeout(() => {
            setAlertMessagesVisibility('daily');
            handleChange((data) => {
              data.flagNotificationDaily = false;
              data.flagNotificationRealtime = false;
            });
          }, 200);
        } else {
          // If the user check daily alert and there is no need to show the daily error alert,
          // ensure to clear the realTime error alert.
          setAlertMessagesVisibility(null);
        }
      } else {
        // when users uncheck we need to hide all alerts messages
        setAlertMessagesVisibility(null);
      }

      handleChange((data) => {
        data.flagNotificationDaily = isChecked;
        if (isChecked) {
          data.flagNotificationRealtime = false;
        }
      });
    },
    [dailyErrorMessage, handleChange, isDailyNotificationFlagBlocked]
  );

  return (
    <NotificationOptions dataCy="frequency-save-search-section">
      <NotificationHeading
        icon="bell"
        title={trans('lbl_save_search_dialog_notifications_subtitle')}
        counter={counterHeader}
        tooltipText={trans('lbl_tooltip_frequency_save_search')}
      />
      <ToggleSwitch
        label={trans('lbl_immediate', { count: 2 })}
        checked={value.flagNotificationRealtime}
        onChange={handleRealTimeChange}
      />

      <AlertError
        show={alertMessagesVisibility === 'realTime'}
        message={realTimeErrorMessage}
      />

      <ToggleSwitch
        label={trans('lbl_once_a_day')}
        checked={value.flagNotificationDaily}
        onChange={handleDailyChange}
      />
      <AlertError
        show={alertMessagesVisibility === 'daily'}
        message={dailyErrorMessage}
      />
    </NotificationOptions>
  );
}
