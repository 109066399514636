import { SniperLinkButton } from '@indomita-react/sniper-link';
import { useTranslations } from '@pepita-react/i18n';

import { PepitaButton } from 'src/libs/ui/pepita-button';

export function SniperLinkButtonWrapper({
  email,
  domainName,
  onClick,
}: {
  email: string | undefined;
  domainName: string;
  onClick: () => void;
}) {
  const { trans } = useTranslations();

  const SniperLinkButtonResult = SniperLinkButton({ email, domainName });

  if (SniperLinkButtonResult) {
    return SniperLinkButtonResult;
  }

  return (
    <PepitaButton
      variant="accent"
      block
      onClick={onClick}
      customClass="ic-sniperLink__button"
    >
      {trans('lbl_ok')}
    </PepitaButton>
  );
}
