import { useAtomicStateAtomValue } from 'src/atoms/atomic-state';
import { formConfigAtom, formStateAtom } from '../atoms';

import type {
  fieldConfigType,
  formConfigType,
} from '../config/formConfigTypes';
import { FormFields } from '../config/formConfigTypes';

import { useSearchFormSelectionValues } from 'src/hooks/useSearchFormState';
import { useDetectHiddenFields } from './useDetectHiddenFields';
import { useGetBaseFilters } from './useGetBaseFilters';

import { removeEmptyKeys } from 'src/utils/object';
import { getDefaultFieldValue } from '../utils/formStateHelpers';

const computeUsedModalFilters = (modalFilters, formState): number => {
  // For each field I get the current values and reduce them to a number
  // showing us how many fields should be counted
  const cleanState = removeEmptyKeys(formState);

  return modalFilters
    .map((field) => {
      switch (field.type) {
        case 'contract':
        case 'category':
          return 1;
        case 'range':
          // Verifying value for the min and the max value
          return Boolean(cleanState[field.fromName]) ||
            cleanState[field.fromName] === 0 ||
            Boolean(cleanState[field.toName]) ||
            cleanState[field.toName] === 0
            ? 1
            : 0;
        default: {
          // Simply verifying that the current value is not equal
          // to the initial one
          if (
            Object.prototype.hasOwnProperty.call(cleanState, field.field) &&
            JSON.stringify(cleanState[field.field]) !==
              JSON.stringify(getDefaultFieldValue(field))
          ) {
            // Custom management for balcony and terrace, because on frontend
            // it is a double filter
            if (field.field === FormFields['BALCONY_OR_TERRACE']) {
              return cleanState[field.field]?.length || 0;
            }

            return 1;
          }

          return 0;
        }
      }
    })
    .reduce((acc, fieldCount) => acc + fieldCount, 0);
};

// Utility for returning fields which set multiple values
// (for example fromName and toName for ranges)
const hasMultipleFields = (field: fieldConfigType) => {
  return ['range', 'category'].indexOf(field.type) !== -1;
};

export const useGetModalFilterCount = () => {
  // Base filters inside the modal (depending on the viewport size)
  const { modalFilters: modalBaseFilters } = useGetBaseFilters(formStateAtom);
  const visibleFilters = useDetectHiddenFields(formStateAtom);

  const formConfig = useAtomicStateAtomValue<formConfigType>(formConfigAtom);

  // All other modal filters
  const modalFilters = formConfig
    .filter(
      (field) =>
        !field.topFilter &&
        visibleFilters.find((filter) => filter.field === field.field)
    )
    .concat(modalBaseFilters);

  // Filters which set multiple fields
  const multipleFieldsFilters = modalFilters.filter(hasMultipleFields);

  // All other filters
  const modalFiltersWithSingleValues = modalFilters.filter(
    (field) => !hasMultipleFields(field)
  );

  // Now I need to extract data from the form state. In order to call
  // the hook only one time, I need to extract all the useful field names
  const neededFields = [];

  multipleFieldsFilters.forEach((field) => {
    switch (field.type) {
      case 'range':
        neededFields.push(field.fromName, field.toName);

        return;
      case 'category':
        neededFields.push(FormFields.CATEGORY, FormFields.TYPOLOGY);

        return;
    }
  });

  neededFields.push(
    ...modalFiltersWithSingleValues.map((field) => field.field)
  );

  // form state contains all the values we need to count filters
  const formState = useSearchFormSelectionValues(neededFields);

  return computeUsedModalFilters(modalFilters, formState);
};
